import {MediaDevice} from './types';

export const SET_DEVICES = 'set_devices';
export const SELECT_DEVICE = 'select_device';

export function setDevices(devices: MediaDevice[]) {
    return {
        devices,
        type: SET_DEVICES,
    }
}

export function selectDevice(deviceId: string) {
    return {
        deviceId,
        type: SELECT_DEVICE,
    }
}
