import {SELECT_DEVICE, SET_DEVICES} from './actions';

const INITIAL_STATE = {
    devices: [],
    width: 1920,
    height: 1080,
};

export default function devices(state = INITIAL_STATE, action: any) {
    switch(action.type) {
        case SET_DEVICES:
            return {
                ...state,
                devices: action.devices,
                selectedDevice: action.devices[0].deviceId,
            };
        case SELECT_DEVICE:
            return {
                ...state,
                selectedDevice: action.deviceId,
            };
        default:
            return state;
    }
}
