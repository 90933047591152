import React from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Webcam from './Webcam.tsx';
import './App.css';

import InputDeviceSelector from './InputDeviceSelector';

import reducer from './reducers.ts';

const store = createStore(reducer);

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <header className="App-header">
            <InputDeviceSelector />
            <Webcam />
        </header>
      </div>
    </Provider>
  );
}

export default App;
