import React from 'react';
import { connect } from 'react-redux';

import Webcam from 'react-webcam';

import {MediaDevice} from './types';
import {setDevices} from './actions';

type PropTypes = {
    webcamHeight: number,
    webcamWidth: number,
    setDevices: any,
    selectedDeviceId: string,
};

const WebcamCapture = ({webcamWidth, webcamHeight, setDevices, selectedDeviceId}: PropTypes) => {
  const contraints = {width: webcamWidth, height: webcamHeight};

  const handleDevices = React.useCallback(
    mediaDevices => {
        const videoDevices = mediaDevices.filter(({ kind } : MediaDevice) => kind === "videoinput");

        setDevices(videoDevices);
    },
    [setDevices]
  );

  React.useEffect(
    () => {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    },
    [handleDevices]
  );

  return (
      <Webcam audio={false} videoConstraints={{...contraints, deviceId: selectedDeviceId}} />
  );
};

const mapStateToProps = (state: any) => {
    return {
        selectedDeviceId: state.selectedDevice,
        webcamWidth: state.width,
        webcamHeight: state.height,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setDevices: (devices: MediaDevice[]) => {
            dispatch(setDevices(devices));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebcamCapture);
