import React from 'react';
import { connect } from 'react-redux';

import {selectDevice} from './actions';
import { MediaDevice } from './types';

type PropTypes = {
    devices: MediaDevice[],
    selectDevice: any,
};

const InputDeviceSelector = ({devices, selectDevice}: PropTypes) => {
    return (
        <select onChange={(event) => {selectDevice(event.target.value)}}>
            {devices.map((device, key) => (
                <option value={device.deviceId} key={key}>{device.label}</option>
            ))}
        </select>
    );
};

const mapStateToProps = (state: any) => {
    return {
        devices: state.devices,
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        selectDevice: (deviceId: string) => {
            dispatch(selectDevice(deviceId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputDeviceSelector);
